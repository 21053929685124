import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
export default {
    data() {
        return {}
    },
    props: {
        labelType: {
            type: Number, // 1: 改， 2：退
            default: 1
        },
        orderNo: { // 改签或退票单号
            type: String,
            default: ''
        },
        passengerList: {
            type: Array,
            default: () => []
        },

    },
    components: {RouterLinkButton},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        toOrderDetail() {
            let routeName = ''
            let query = {}
            if (this.labelType === 1) {
                routeName = 'air-ticket-admin-airChangeDetails'
                let psgType, psgNo
                let psg = this.passengerList.map(item => {
                    return item.psgType
                })
                psgNo = this.passengerList.map(item => {
                    return item.psgNo
                })
                if (psg.indexOf(1) !== -1) {
                    psgType = 1
                } else {
                    psgType = 2
                }
                query = {
                    ordersNo: this.orderNo,
                    orderType: 'change',
                    psgType,
                    psgNo: JSON.stringify(psgNo),
                }
            } else {
                routeName = 'air-ticket-admin-airRefundDetails'
                query = {
                    ordersNo: this.orderNo,
                    orderType: 'refund',
                    psgType: 1
                }
            }
            return {
                name: routeName,
                query
            }
        },
    }
}